<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    temporary
    flat
  >
    <v-list>
      <v-list-item
        href="/"
      >
        <v-list-item-title>Peppol Directory [Test]</v-list-item-title>
      </v-list-item>
      <v-list-item
        to="/about"
      >
        <v-list-item-title>About</v-list-item-title>
      </v-list-item>
      <v-list>
        <v-list-group
          v-for="item in items"
          no-action
          v-bind:key="item.title"
        >
          <template v-slot:activator>
            <v-list-item-title>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item-title>
          </template>

          <v-list-item
            v-for="subItem in item.subItems"
            :href="subItem.link"
            :to="subItem.to"
            v-bind:key="subItem.title"
          >
            <v-list-item-title>{{ subItem.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
      <v-list-item
        v-if="isLoggedin"
        to="/admin"
      >
        <v-list-item-title>Admin</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="!isLoggedin"
        to="/login"
      >
        Login
      </v-list-item>
      <v-list-item
        v-if="isLoggedin"
        @click="logout"
      >
        Logout
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'CoreDrawer',
  data() {
    return {
      items: [
        {
          title: 'Documentation',
          subItems: [
            {
              title: 'Introduction',
              to: 'document-introduction',
            },
            {
              title: 'How To Use It',
              to: 'document-how-to-use',
            },
            {
              title: 'REST Api',
              to: 'document-rest-api',
            },
            {
              title: 'Export Data',
              to: 'document-export-data',
            },
            {
              title: 'Specification v1.1 (PDF)',
              link: 'files/PEPPOL-EDN-Directory-1.1.1-2020-10-15.pdf',
            },
            {
              title: 'Guide for SMP Providers (PDF)',
              link: 'files/OpenPEPPOL%20Directory%20for%20SMP%20providers%202016-12-05.pdf',
            },
          ],
        },
        {
          title: 'Support',
          subItems: [
            {
              title: 'Peppol Service Desk',
              link: 'https://openpeppol.atlassian.net/servicedesk/customer/portal/1',
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['links', 'isLoggedin']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.setDrawer(val)
      },
    },
  },

  methods: {
    ...mapMutations(['setDrawer', 'setAuthUser']),
    onClick(e, item) {
      e.stopPropagation()

      if (item.to === '/') {
        this.$vuetify.goTo(0)
        this.setDrawer(false)
        return
      }

      if (item.to || !item.href) return

      this.$vuetify.goTo(item.href)
      this.setDrawer(false)
    },
    logout() {
      this.setAuthUser(null);
      this.$router.push('login')
    }
  },
}
</script>
